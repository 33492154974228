<template>
  <v-container fluid class="px-lg-10">
    <UploadsSectionSearch @search="() => $refs.table.refresh()" />
    <BaseTable
      ref="table"
      title="Gestione import"
      module="uploads"
      :headers="headers"
      :actions="actions"
      sortby="import_started_at"
    >
      <template v-slot:item.import_started_at="{ item }">
        {{ item.import_started_at | momentOr('DD/MM/YYYY HH:MM', '-') }}
      </template>
      <template v-slot:item.import_ended_at="{ item }">
        {{ item.import_ended_at | momentOr('DD/MM/YYYY HH:MM', '-') }}
      </template>
      <template v-slot:item.status="{ item }">
        {{
          item.status === 'pending'
            ? 'In corso'
            : item.status === 'done'
            ? 'Completato'
            : 'Errore'
        }}
      </template>
    </BaseTable>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'

import { authComputed } from '@state/helpers.js'
import UploadsSectionSearch from '@components/uploads/UploadsSectionSearch.vue'

export default {
  name: 'ImporterSearch',
  components: {
    UploadsSectionSearch,
    BaseTable,
  },
  page: {
    title: 'Import',
  },
  data() {
    return {
      headers: [
        {
          text: 'Tipo',
          value: 'type',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'Nome File',
          value: 'filename',
        },
        {
          text: '#Records',
          value: 'num_records',
        },
        {
          text: 'Processati',
          value: 'processed',
        },
        {
          text: 'Errori',
          value: 'error',
        },
        {
          text: 'Caricato da',
          value: 'uploaded_by',
        },
        {
          text: 'Inizio import',
          value: 'import_started_at',
        },
        {
          text: 'Fine import',
          value: 'import_ended_at',
        },
      ],
      actions: [
        {
          key: 'view',
          label: 'Dettagli Errore',
          icon: 'mdi-magnify',
          color: 'primary',
          button: true,
          disabled: (item) => !item.error,
          to: (item) => ({
            name: 'uploaded_files_error_details',
            params: { id: item.id },
          }),
          onItemCondition: () => this.canUser('uploaded_files', 'read'),
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
}
</script>
