<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="type"
        type="text"
        name="Tipo"
        hint="Ricerca per tipo"
        :cols="3"
        :sm="3"
        :md="3"
        :lg="3"
        dense
      />
      <FormItem
        v-model="status"
        type="select"
        name="Filtra per Stato"
        :values="[
          { value: 'done', text: 'Completato' },
          { value: 'pending', text: 'In corso' },
          { value: 'error', text: 'Errore' },
        ]"
        dense
        :cols="3"
        :sm="3"
        :md="3"
        :lg="3"
      />

      <FormItem
        v-model="filename"
        type="text"
        name="File Nome"
        hint="Ricerca per file nome"
        dense
        :cols="3"
        :sm="3"
        :md="3"
        :lg="3"
      />
      <FormItem
        v-model="uploaded_by"
        type="text"
        name="Caricato da"
        hint="Ricerca per utente"
        dense
        :cols="3"
        :sm="3"
        :md="3"
        :lg="3"
      />
    </v-row>
    <v-row dense>
      <FormItem
        v-model="import_started_at"
        type="datepicker"
        name="Inizio import"
        hint="Ricerca per data inizio import"
        dense
        :cols="3"
        :sm="3"
        :md="3"
        :lg="3"
      />
      <FormItem
        v-model="import_ended_at"
        type="datepicker"
        name="Fine import"
        hint="Ricerca per data fine import"
        dense
        :cols="3"
        :sm="3"
        :md="3"
        :lg="3"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'uploads/getFiltersFields',
  mutationType: 'uploads/SET_FILTER_FIELDS',
})

export default {
  name: 'UploadsSectionSearch',
  components: { FormItem, BaseSectionSearch },
  computed: {
    ...mapFields([
      'type',
      'status',
      'filename',
      'uploaded_by',
      'import_started_at',
      'import_ended_at',
    ]),
    ...mapState('uploads', ['filters']),
  },
  mounted() {
    this.reset()
  },
  methods: {
    ...mapMutations('uploads', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
